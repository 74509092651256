const Utm = (signupLink) => {
    const isBrowser = typeof window !== "undefined"
    if(isBrowser){
        let link = signupLink;
        var str = window.location.search;
        var objURL = {};

        str.replace(
            new RegExp( "([^?=&]+)(=([^&]*))?", "g" ),
            function( $0, $1, $2, $3 ){
                objURL[ $1 ] = $3;
            }
        );
        if(str.includes('utm') || str.includes('referrer')){
            sessionStorage.setItem('utmLink',str);
        } 
        if(sessionStorage.getItem('utmLink')){
            link += sessionStorage.getItem('utmLink');
        }
        return link;
    }   
 }
export default (Utm);
